import { LOCALES } from '../locales'

const localesNL = {
	[LOCALES.DUTCH]: {
		//MENU
		rankingsRT: 'Regulariteitstesten',
		rankingsZR: 'Regulariteitstesten',
		rankingsEC: 'Timed Stages',
		rankingsSS: 'Special Stages',
		rankingsOV: 'Algemeen Klassement',
		departures: 'Starttijden',
		startList: 'Deelnemerslijst',
		//COLUMNS
		position: 'Pos',
		experience: 'Exp',
		crew: 'Crew',
		car: 'Wagen',
		vehicle: 'Vehicle',
		total: 'Totaal',
		time: 'Tijd',
		difference: 'Versch.',
		differenceFirst: 'Versch. 1st',
		year: 'Jaar',
		groupShort: 'Gr',
		classShort: 'Cl',
		group: 'Groep',
		class: 'Klasse',
		capacity: 'Cil',
		race: 'Categorie',
		avg: 'Gem',
		coef: 'Coëf',
		team: 'Team',
		period: 'Period',
		plateau: 'Plateau',
		rt: 'RT',
		rz: 'RZ',
		ec: 'EC',
		ss: 'SS',
		ch: 'CH',
		sc: 'Snel',
		cp: 'CP',
		chs: 'TCS',
		pen: 'Straf',
		joker: 'Joker',
		timeAM: '{am} Time',
		timePM: '{pm} Time',
		firstRZ: '1st RZ',
		leg: 'Leg',
		day: 'Day',
		stage: 'Stage',
		//BOUTONS
		summary: 'Overzicht',
		detailed: 'Detail',
		overall: 'Algemeen',
		rtSummary: 'RT Overzicht',
		rtDetailed: 'RT Detail',
		rzSummary: 'RZ Summary',
		rzDetailed: 'RZ Detailed',
		ovSummary: 'Algemeen Overzicht',
		ovDetailed: 'Algemeen Detail ',
		gcSummary: 'GC Summary',
		gcDetailed: 'GC Detailed',
		//TOOLTIPS
		TTcarNumber: 'Voer een autonummer in om het te markeren',
		TTgroup: 'Groep',
		TTgroupPos: 'Groepspositie',
		TTclass: 'Klasse',
		TTclassPos: 'Klasse Positie',
		TTch: 'Tijdscontroles',
		TTsc: 'Snelheidsstraffen',
		TTcp: 'Doorgangscontroles',
		TTpen: 'Andere Straffen',
		//MISC
		canceled: 'Geannuleerd',
		all: 'Alle',
	},
}

export default localesNL
