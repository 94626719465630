import { LOCALES } from '../locales'

const localesGB = {
	[LOCALES.ENGLISH]: {
		//MENU
		rankingsRT: 'Regularity Tests',
		rankingsZR: 'Regularity Tests',
		rankingsEC: 'Timed Stages',
		rankingsSS: 'Special Stages',
		rankingsOV: 'General Class.',
		departures: 'Start Order',
		startList: 'Entry List',
		//COLUMNS
		position: 'Pos',
		experience: 'Exp',
		crew: 'Crew',
		car: 'Car',
		vehicle: 'Vehicle',
		total: 'Total',
		time: 'Time',
		difference: 'Dif',
		differenceFirst: 'Dif 1st',
		year: 'Year',
		groupShort: 'Gr',
		classShort: 'Cl',
		group: 'Group',
		class: 'Class',
		capacity: 'Capacity',
		race: 'Race',
		avg: 'AVG',
		coef: 'Coef',
		team: 'Team',
		period: 'Period',
		plateau: 'Plateau',
		rt: 'RT',
		rz: 'RZ',
		ec: 'EC',
		ss: 'SS',
		ch: 'TC',
		sc: 'SC',
		cp: 'CP',
		chs: 'TCS',
		pen: 'Pen',
		joker: 'Joker',
		timeAM: '{am} Time',
		timePM: '{pm} Time',
		firstRZ: '1st RZ',
		leg: 'Leg',
		day: 'Day',
		stage: 'Stage',
		//BOUTONS
		summary: 'Summary',
		detailed: 'Detailed',
		overall: 'Overall',
		rtSummary: 'RT Summary',
		rtDetailed: 'RT Detailed',
		rzSummary: 'RZ Summary',
		rzDetailed: 'RZ Detailed',
		ovSummary: 'OV Summary',
		ovDetailed: 'OV Detailed',
		gcSummary: 'GC Summary',
		gcDetailed: 'GC Detailed',
		//TOOLTIPS
		TTcarNumber: 'Insert a crew number to highlight it',
		TTgroup: 'Group',
		TTgroupPos: 'Group position',
		TTclass: 'Class',
		TTclassPos: 'Class position',
		TTch: 'Time Control',
		TTsc: 'Speed Control',
		TTcp: 'Check points',
		TTpen: 'Other Penalties',
		//MISC
		canceled: 'CANCELED',
		all: 'All',
	},
}

export default localesGB
