import { FormGroup, FormControlLabel, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
	root: {
		paddingTop: 25,
	},
})

function Settings({ isDarkMode, themeChange }) {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<FormGroup row>
				<FormControlLabel
					control={
						<Switch
							checked={isDarkMode}
							onChange={e => themeChange(e.target.checked)}
							name="isDarkMode"
							inputProps={{ 'aria-label': 'secondary checkbox' }}
						/>
					}
					label="Dark mode"
					labelPlacement="start"
				/>
			</FormGroup>
		</div>
	)
}

export default Settings
