import { LOCALES } from '../locales'

const localesES = {
	[LOCALES.SPANISH]: {
		//MENU
		rankingsRT: 'Zona Regularidad',
		rankingsZR: 'Zona Regularidad',
		rankingsEC: 'Eventos Cronometrados',
		rankingsOV: 'Clas. General',
		departures: 'Horas de salida',
		startList: 'Lista participantes',
		//COLUMNS
		position: 'Pos',
		experience: 'Exp',
		crew: 'Equipo',
		car: 'Carro',
		vehicle: 'Vehiculo',
		total: 'Total',
		time: 'Time', //TODO: translate
		difference: 'Dif',
		differenceFirst: 'Dif 1ro',
		year: 'Año',
		groupShort: 'Gr',
		classShort: 'Cl',
		group: 'Grupo',
		class: 'Clase',
		capacity: 'Desplazamiento',
		race: 'Raza',
		avg: 'AVG',
		coef: 'Coef',
		team: 'Team',
		period: 'Período',
		plateau: 'Plateau',
		rt: 'RT',
		rz: 'ZR',
		ec: 'EC',
		ss: 'SS',
		ch: 'CH',
		sc: 'CV',
		cp: 'CP',
		chs: 'TCS',
		pen: 'Pen',
		joker: 'Joker',
		timeAM: 'Horas {am}',
		timePM: 'Horas {pm}',
		firstRZ: '1ro ZR',
		leg: 'Etapa',
		day: 'Día',
		stage: 'Etapa',
		//BOUTONS
		summary: 'Resumen',
		detailed: 'Detalles',
		overall: 'General',
		rtSummary: 'Resumen RT',
		rtDetailed: 'Detalles RT',
		rzSummary: 'Resumen ZR',
		rzDetailed: 'Detalles ZR',
		ovSummary: 'Resumen OV',
		ovDetailed: 'Detalles OV',
		gcSummary: 'Resumen CG',
		gcDetailed: 'Detalles CG',
		//TOOLTIPS
		TTcarNumber: 'Insertar un número de equipo para resaltarlo',
		TTgroup: 'Grupo',
		TTgroupPos: 'Group posición',
		TTclass: 'Clase',
		TTclassPos: 'Clase posición',
		TTch: 'Control horario',
		TTsc: 'Control de velocidad',
		TTcp: 'Control de passo',
		TTpen: 'Otras sanciones',
		//MISC
		canceled: 'ANULADO',
		all: 'Todas',
	},
}

export default localesES
