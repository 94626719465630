import { LOCALES } from '../locales'

const localesFR = {
	[LOCALES.FRENCH]: {
		//MENU
		rankingsRT: 'Tests de Régularité',
		rankingsZR: 'Zones de Régularité',
		rankingsEC: 'Epreuves chronométrées',
		rankingsSS: 'Classements SS',
		rankingsOV: 'Class. Général',
		departures: 'Heures de départ',
		startList: 'Liste des partants',
		//COLUMNS
		position: 'Pos',
		experience: 'Exp',
		crew: 'Equipage',
		car: 'Voiture',
		vehicle: 'Véhicule',
		total: 'Total',
		time: 'Temps',
		difference: 'Dif',
		differenceFirst: 'Dif 1er',
		year: 'Année',
		groupShort: 'Gr',
		classShort: 'Cl',
		group: 'Groupe',
		class: 'Classe',
		capacity: 'Cylindrée',
		race: 'Catégorie',
		avg: 'MOY',
		coef: 'Coef',
		team: 'Equipe',
		period: 'Période',
		plateau: 'Plateau',
		rt: 'RT',
		rz: 'ZR',
		ec: 'EC',
		ss: 'SS',
		ch: 'CH',
		sc: 'VIT',
		cp: 'CP',
		chs: 'CHS',
		pen: 'Pen',
		joker: 'Joker',
		timeAM: 'Heure {am}',
		timePM: 'Heure {pm}',
		firstRZ: '1ère ZR',
		leg: 'Etape',
		day: 'Jour',
		stage: 'Etape',
		//BOUTONS
		summary: 'Résumé',
		detailed: 'Détails',
		overall: 'Général',
		rtSummary: 'RT Résumé',
		rtDetailed: 'RT Détails',
		rzSummary: 'ZR Résumé',
		rzDetailed: 'ZR Détails',
		ovSummary: 'CG Résumé',
		ovDetailed: 'CG Détais',
		gcSummary: 'CG Résumé',
		gcDetailed: 'CG Détails',
		//TOOLTIPS
		TTcarNumber: 'Insérez un numéro d’équipage pour le mettre en surbrillance',
		TTgroup: 'Groupe',
		TTgroupPos: 'Position groupe',
		TTclass: 'Classe',
		TTclassPos: 'Position classe',
		TTch: 'Contrôles horaires',
		TTsc: 'Pénalités vitesse',
		TTcp: 'Contrôles de passage',
		TTpen: 'Autres pénalités',
		//MISC
		canceled: 'ANNULÉ',
		all: 'Tous',
	},
}

export default localesFR
